import { useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Fragment, useContext, useState } from "react";
import ReactGA from "react-ga4";
import { Navigate } from "react-router";
import { useNavigate } from "react-router-dom";

import FAQSection from "../FAQ/FAQHub";
import SubscriptionModal, { SubscriptionModalType } from "../SubscriptionModal";
import UsageCalculator from "./PassioHubCalculator";
import { DynamicVolumeSubscriptionSettings } from "./PassioHubCalculator";

import UserContext from "context/UserContext";

import Modal from "components/atoms/Modal";
import Spinner from "components/atoms/Spinner";
import {
  calculatePrice,
  getPrettyDisplayPrice,
} from "components/molecules/PriceTables/PriceTableNutritionAPI";
import PriceTablePassioHub from "components/molecules/PriceTables/PriceTablePassioHub";
import SubscriptionCard from "components/molecules/SubscriptionCard";

import { error, success } from "utils";
import { PASSIO_HUB_SLUG, uniqueEventId } from "utils/products";
import { TierType } from "utils/types";
import { CustomerStatusType, UsageInfoType } from "utils/types";

// Add this import at the top of the file

interface PassioHubTierType {
  isSubscribed: boolean | null;
  planTitle: string | null;
  usageInfo: UsageInfoType | null;
}

const subscriptionSettings: DynamicVolumeSubscriptionSettings[] = [
  {
    quantity: 10,
    refillBehaviour: "auto",
    refillBatchQuantity: 2,
    planTitle: "Starter",
  },
  {
    quantity: 150,
    refillBehaviour: "auto",
    refillBatchQuantity: 10,
    planTitle: "Growth",
  },
  {
    quantity: 2000,
    refillBehaviour: "auto",
    refillBatchQuantity: 20,
    planTitle: "Pro",
  },
];

const ManageSubscriptionPassioHub = () => {
  const navigate = useNavigate();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const productQuery = params.get("product");
  const [planPrices, setPlanPrices] = useState<number[]>([0, 0, 0]);

  const [unauthorized, setUnauthorized] = useState(false);

  const [customerStatus, setCustomerStatus] = useState<CustomerStatusType>({
    hasOrg: false,
    hasPaymentMethod: false,
  });
  const [productState, setProductState] = useState<PassioHubTierType>({
    isSubscribed: null,
    planTitle: null,
    usageInfo: null,
  });
  const [productDetail, setProductDetail] = useState<any>({});

  const [priceTiers, setPriceTiers] = useState<TierType[]>([]); // Add this line

  const [subscriptionModal, setSubscriptionModal] =
    useState<SubscriptionModalType>({
      isOpen: false,
      planSlug: null,
      shouldUpdate: false,
    });

  const [targetSubDetails, setTargetSubDetails] =
    useState<DynamicVolumeSubscriptionSettings>(subscriptionSettings[0]);

  const [unsubscribeModalIsOpen, setUnsubscribeModalIsOpen] = useState(false);
  const setAllData = (data: any) => {
    setPriceTiers(data.tiers);
    setPlanPrices(
      subscriptionSettings.map((setting) =>
        calculatePrice(setting.quantity, data.tiers)
      )
    );
    setProductDetail(data);
    setCustomerStatus((prev) => ({
      ...prev,
      hasOrg: data.hasOrg,
      hasPaymentMethod: data.hasPaymentMethod,
    }));
    setProductState((prev) => ({
      ...prev,
      isSubscribed: data.isSubscribed,
      planTitle: data.planTitle,
      usageInfo: data.usageInfo,
    }));
  };

  const userContext = useContext(UserContext);

  const getProduct = async () =>
    await axios.get(`/accounts/products/${productQuery}/detail`);

  const { isLoading, isFetching, isPaused, refetch } = useQuery(
    ["product"],
    getProduct,
    {
      enabled: !!productQuery && !!userContext,
      onError: (err: AxiosError) => {
        error(err?.response?.data?.cause || "Could not fetch the products");
        if (err?.response?.status === 403) {
          setUnauthorized(true);
        }
      },
      onSuccess: (res: AxiosResponse) => {
        setAllData(res?.data);
      },
    }
  );

  const updateSubscription = useMutation(
    async (plan: DynamicVolumeSubscriptionSettings) => {
      // const data = { planTitle };
      return await axios.put(
        `/accounts/products/nutrition-hub/subscription`,
        plan
      );
    },
    {
      onError: (err: AxiosError) => {
        error(err?.response?.data?.cause || "Something went wrong");
      },
      onSuccess: (res: AxiosResponse) => {
        success("Subscription updated successfully");
        setAllData(res?.data);
        // google event
        ReactGA.event({
          action: "subscriptionupdated",
          category: `${PASSIO_HUB_SLUG}_${productState.planTitle?.toLowerCase()}}`,
          label: uniqueEventId(),
        });
      },
    }
  );

  const unsubscribe = useMutation(
    async () =>
      await axios.delete(`/accounts/products/nutrition-hub/subscription`),
    {
      onError: (err: AxiosError) => {
        error(err?.response?.data?.cause || "Something went wrong");
      },
      onSuccess: () => {
        success("Unsubscribed successfully");
        setUnsubscribeModalIsOpen(false);

        // google event
        ReactGA.event({
          action: "subscriptioncancelled",
          category: `${PASSIO_HUB_SLUG}_${productState.planTitle?.toLowerCase()}`,
          label: uniqueEventId(),
        });

        // Reset the product state
        setProductState((prev) => ({
          ...prev,
          isSubscribed: false,
          planTitle: null,
          usageInfo: null,
        }));
      },
    }
  );

  if (unauthorized) {
    return <Navigate to="/access-restricted" />;
  }

  function closeSubscriptionModal() {
    // Refetch the data when we close the modal, because it may have been changed
    refetch();
    setSubscriptionModal({
      isOpen: false,
      planSlug: null,
      shouldUpdate: false,
    });
  }

  const buttonOnClickForPlan = (
    plan: DynamicVolumeSubscriptionSettings,
    isSubscribed: boolean
  ) => {
    // If we're not subscribed, we need to open the subscription modal.
    if (!isSubscribed) {
      return () => {
        setTargetSubDetails(plan);
        setSubscriptionModal({
          isOpen: true,
          planSlug: "nutrition-hub",
          shouldUpdate: false,
        });
      };
    }

    // If we're already subscribed, just update the subscription.
    return () => updateSubscription.mutate(plan);
  };

  const getCurrentSubUnitCap = () => {
    return productDetail?.usageInfo?.unitsQuota;
  };

  const getCurrentTier = () => {
    const cap = getCurrentSubUnitCap() / 1000000;
    for (let i = 0; i <= subscriptionSettings?.length; i++) {
      if (
        cap >= subscriptionSettings[i]?.quantity &&
        (subscriptionSettings?.length == i + 1 ||
          cap < subscriptionSettings[i + 1]?.quantity)
      ) {
        return i;
      }
    }
    return 0;
  };

  const isCurrentTier = (current: number) => {
    return getCurrentTier() == current;
  };

  const buttonTextForDVSTier = (tier: number, isSubscribed: boolean) => {
    const curTierInt = getCurrentTier();
    if (!isSubscribed || curTierInt < 0) {
      return "Subscribe";
    }
    if (tier < curTierInt) {
      return "No Downgrade Available";
    }
    if (tier > curTierInt) {
      return "Upgrade";
    }
    return "";
  };

  const isAdmin = () => {
    if (userContext === null) {
      return false;
    }
    if (userContext.isOrgOwner) {
      return true;
    }
    let admin = false;
    userContext.roles.forEach((element) => {
      if (element.toLowerCase() === "admin") {
        admin = true;
      }
    });
    return admin;
  };

  return (
    <Fragment>
      <div>
        <div className="flex justify-between items-center mb-6">
          <h2 className="font-bold inter-bold text-md md:text-lg xl:text-3xl">
            Nutrition AI Hub Subscription
          </h2>
        </div>
        {isFetching ||
        isLoading ||
        isPaused ||
        updateSubscription.isLoading ||
        priceTiers?.length <= 0 ||
        !planPrices ? (
          <Spinner />
        ) : (
          <>
            <div className="flex flex-wrap place-content-evenly h-full">
              <>
                <SubscriptionCard
                  name="Starter"
                  price={
                    <>
                      <div className="text-gray-900 font-inter text-6xl font-extrabold leading-[60px]">
                        {getPrettyDisplayPrice(planPrices[0])}
                      </div>
                      <div className="text-gray-500 font-inter text-2xl font-medium leading-8">
                        /mo
                      </div>
                    </>
                  }
                  counterInput={<></>}
                  description="Tailored to new or small-scale applications preparing to grow"
                  descriptionAlt="(for roughly 1-50 users)"
                  bulletsHtml={[
                    <>
                      <span className="font-extrabold">{`${subscriptionSettings[0]?.quantity}M `}</span>
                      <span className="">tokens per month included</span>
                      <span className="block">
                        (see usage calculator below)
                      </span>
                    </>,
                    <>
                      <span className="font-extrabold">{`$${priceTiers[0]?.unitPrice?.toFixed(
                        2
                      )} `}</span>
                      <span className="">per million tokens included</span>
                    </>,
                    <>
                      <span className="font-extrabold">{`$${priceTiers[0]?.unitPrice?.toFixed(
                        2
                      )} `}</span>
                      <span className="">for every additional 1M tokens</span>
                    </>,
                  ]}
                  bullets={[
                    "Access food databse REST API",
                    "Access to Nutrition Advisor",
                    "Mobile app SDK quick-start app",
                    "Optional auto-credit refills as required",
                    "Detailed documentation",
                  ]}
                  isCurrent={
                    isCurrentTier(0) && (productState?.isSubscribed || false)
                  }
                  buttonText={buttonTextForDVSTier(
                    0,
                    productState?.isSubscribed || false
                  )}
                  buttonOnClick={buttonOnClickForPlan(
                    subscriptionSettings[0],
                    productState?.isSubscribed || false
                  )}
                  hideButton={getCurrentTier() > 0}
                  isCurrentButtonText="Product Details"
                  isCurrentButtonOnClick={() =>
                    navigate(`/details?product=${productQuery}`)
                  }
                  disabled={
                    isLoading ||
                    isFetching ||
                    isPaused ||
                    updateSubscription.isLoading ||
                    !isAdmin()
                  }
                  wide={false}
                  showCancelButton={
                    isCurrentTier(0) && (productState?.isSubscribed || false)
                  }
                  cancelButtonOnClick={() => setUnsubscribeModalIsOpen(true)}
                />
                <SubscriptionCard
                  name="Growth"
                  description="Tailored to existing applications with a growing user base"
                  descriptionAlt="(for roughly 100-750 users)"
                  price={
                    <>
                      <div className="text-gray-900 font-inter text-6xl font-extrabold leading-[60px]">
                        {getPrettyDisplayPrice(planPrices[1])}
                      </div>
                      <div className="text-gray-500 font-inter text-2xl font-medium leading-8">
                        /mo
                      </div>
                    </>
                  }
                  bulletsHtml={[
                    <>
                      <span className="font-extrabold">{`${subscriptionSettings[1]?.quantity}M `}</span>
                      <span className="">tokens per month included</span>
                      <span className="block">
                        (see usage calculator below)
                      </span>
                    </>,
                    <>
                      <span className="font-extrabold">{`$${priceTiers[1]?.unitPrice?.toFixed(
                        2
                      )} `}</span>
                      <span className="">per million tokens included</span>
                    </>,
                    <>
                      <span className="font-extrabold">{`$${priceTiers[0]?.unitPrice?.toFixed(
                        2
                      )} `}</span>
                      <span className="">for every additional 1M tokens</span>
                    </>,
                  ]}
                  bullets={[
                    "Access food databse REST API",
                    "Access to Nutrition Advisor",
                    "Mobile app SDK quick-start app",
                    "Optional auto-credit refills as required",
                    "Detailed documentation",
                    "Dedicated Slack channel for support",
                  ]}
                  isCurrent={
                    isCurrentTier(1) && (productState?.isSubscribed || false)
                  }
                  buttonText={buttonTextForDVSTier(
                    1,
                    productState?.isSubscribed || false
                  )}
                  buttonOnClick={buttonOnClickForPlan(
                    subscriptionSettings[1],
                    productState?.isSubscribed || false
                  )}
                  hideButton={getCurrentTier() > 1}
                  isCurrentButtonText="Product Details"
                  isCurrentButtonOnClick={() =>
                    navigate(`/details?product=${productQuery}`)
                  }
                  disabled={
                    isLoading ||
                    isFetching ||
                    isPaused ||
                    updateSubscription.isLoading ||
                    !isAdmin()
                  }
                  wide={false}
                  counterInput={<></>}
                  showCancelButton={
                    isCurrentTier(1) && (productState?.isSubscribed || false)
                  }
                  cancelButtonOnClick={() => setUnsubscribeModalIsOpen(true)}
                />
                <SubscriptionCard
                  name="Pro"
                  description="For businesses with high volume requirements"
                  descriptionAlt="(for roughly 1,000-10,000 users)"
                  price={
                    <>
                      <div className="text-gray-900 font-inter text-6xl font-extrabold leading-[60px]">
                        {getPrettyDisplayPrice(planPrices[2])}
                      </div>
                      <div className="text-gray-500 font-inter text-2xl font-medium leading-8">
                        /mo
                      </div>
                    </>
                  }
                  counterInput={<></>}
                  bulletsHtml={[
                    <>
                      <span className="font-extrabold">{`${subscriptionSettings[2]?.quantity}M `}</span>
                      <span className="">tokens per month included</span>
                      <span className="block">
                        (see usage calculator below)
                      </span>
                    </>,
                    <>
                      <span className="font-extrabold">{`$${priceTiers[2]?.unitPrice?.toFixed(
                        2
                      )} `}</span>
                      <span className="">per million tokens included</span>
                    </>,
                    <>
                      <span className="font-extrabold">{`$${priceTiers[0]?.unitPrice?.toFixed(
                        2
                      )} `}</span>
                      <span className="">for every additional 1M tokens</span>
                    </>,
                  ]}
                  bullets={[
                    "Access food databse REST API",
                    "Access to Nutrition Advisor",
                    "Mobile app SDK quick-start app",
                    "Optional auto-credit refills as required",
                    "Detailed documentation",
                    "Dedicated Slack channel for support",
                    "Dedicated integration support",
                    "Design customization support",
                  ]}
                  isCurrent={productState.planTitle === "high"}
                  buttonText={buttonTextForDVSTier(
                    2,
                    productState?.isSubscribed || false
                  )}
                  buttonOnClick={buttonOnClickForPlan(
                    subscriptionSettings[2],
                    productState?.isSubscribed || false
                  )}
                  isCurrentButtonText="Product Details"
                  isCurrentButtonOnClick={() =>
                    navigate(`/details?product=${productQuery}`)
                  }
                  disabled={
                    isLoading ||
                    isFetching ||
                    isPaused ||
                    updateSubscription.isLoading ||
                    !isAdmin()
                  }
                  wide={false}
                  showCancelButton={isCurrentTier(2)}
                  cancelButtonOnClick={() => setUnsubscribeModalIsOpen(true)}
                />
              </>
            </div>
            <div className="grid grid-cols-12 gap-4 mt-6">
              <div className="col-span-12 overflow-auto overflow-y-visible h-auto">
                <UsageCalculator
                  pricingTiers={priceTiers}
                  subscriptionSettings={subscriptionSettings}
                  priceTable={
                    <PriceTablePassioHub
                      isLoading={isLoading || isFetching}
                      tiers={priceTiers}
                      showDescription={false}
                      showTitle={false}
                      renderCard={false}
                    />
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>
      <FAQSection></FAQSection>
      {subscriptionModal.isOpen && subscriptionModal.planSlug && (
        <SubscriptionModal
          isOpen={subscriptionModal.isOpen}
          closeModal={closeSubscriptionModal}
          closeBtn
          planSlug={subscriptionModal.planSlug}
          useTiers={priceTiers}
          subscriptionData={targetSubDetails}
          shouldCreateOrg={!customerStatus.hasOrg}
          shouldUpdate={subscriptionModal.shouldUpdate}
          shouldCapturePayment={!customerStatus.hasPaymentMethod}
        />
      )}
      <Modal
        isOpen={unsubscribeModalIsOpen}
        setOpen={(e) => setUnsubscribeModalIsOpen(e)}
        title={"Unsubscribe from Passio Hub"}
        disabled={unsubscribe.isLoading}
        isLoading={unsubscribe.isLoading}
        description={`Are you sure you want to unsubscribe? Your subscription will be cancelled immediately. This action cannot be undone.`}
        submitButtonName={"Unsubscribe"}
        buttonType={"warning"}
        type={"error"}
        onSubmitted={() => unsubscribe.mutate()}
      />
    </Fragment>
  );
};

export default ManageSubscriptionPassioHub;
